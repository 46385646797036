export const KPIs = {
  ESB: 'ESB',
  NSB: 'NSB',
  SB: 'SB',
  NRR: 'NRR',
  SAU: 'SAU',
  ARPES: 'ARPES',
  ARPNS: 'ARPNS',
  ARPS: 'ARPS',
  INSTALLS: 'Installs',
  INSTALL_TO_PAID: 'Install to Paid',
  INSTALL_TO_TRIAL: 'Install to Trial',
  TRIAL_TO_PAID: 'Trial To Paid',
  REVENUE_ESB: 'Revenue ESB',
  REVENUE_NSB: 'Revenue NSB',
  REVENUE: 'Revenue',
  SPENT: 'Spent',
  CPI: 'CPI',
  CPA: 'CPA',
  MARGIN_PERCENT: 'Margin (%)',
  MARGIN_EUR: 'Margin (eur)',
  CHURN: 'Churn',
  ROAS: 'ROAS',

  // Studio Perf
  REVENUE_IAP: 'Revenue IAP',
  REVENUE_SUBS: 'Revenue Subs',

  MARGIN_W_ADS_EUR: 'Margin w/ Ads',
  MARGIN_WO_ADS_EUR: 'Margin w/o Ads',
  MARGIN_W_ADS_PERCENT: 'Margin w/ Ads(%)',
  MARGIN_WO_ADS_PERCENT: 'Margin w/o Ads(%)',
};

export const KPIsMoney = [
  KPIs.ARPES,
  KPIs.ARPNS,
  KPIs.ARPS,
  KPIs.CPA,
  KPIs.CPI,
  KPIs.MARGIN_EUR,
  KPIs.MARGIN_W_ADS_EUR,
  KPIs.MARGIN_WO_ADS_EUR,
  KPIs.SPENT,
  KPIs.REVENUE,
  KPIs.ROAS,
];

export const KPIsPercent = [
  KPIs.INSTALL_TO_PAID,
  KPIs.INSTALL_TO_TRIAL,
  KPIs.TRIAL_TO_PAID,
  KPIs.MARGIN_PERCENT,
  KPIs.MARGIN_W_ADS_PERCENT,
  KPIs.MARGIN_WO_ADS_PERCENT,
];

export const KPIsWithNegativeSign = [
  KPIs.CHURN,
  KPIs.CPA,
  KPIs.CPI,
  KPIs.SPENT,
  KPIs.NRR,
];

export const KPIsRounded = [
  // KPIs.ROAS,
];
